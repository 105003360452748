import React, { useState } from 'react';

//  import Orderon from "./Orderon";

import Sanity from "./sanity";

function Front() {
  let [loggedin, setLoggedin] = useState(true);
  let [admin, setAdmin] = useState(false);

  const [login, setLogin] = useState('jessica');
  const [password, setPassword] = useState('bird');

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (login === 'jessica') {
      if (password === 'bird') {
        setLoggedin(true);
      }
    }
  }

  if (loggedin) {
    if (admin) {
      return (
        <div id="notLoggedIn">
          sux
        </div>
      )
    }
    else {
      return (
        <div className="Front">
          <header className="App-header">
            <div id="orderOn">OrderOn from <a href="https://pacificio.com">pacificio.com</a></div>
          </header>

          <Sanity customer={login} />
          <style>
            {`
            div#orderOn {
              display: block;
              font-size: calc(1.5rem);
              font-weight: bolder;
            }
          `}
          </style>

        </div>
      );
    }

  }
  else {
    return (
      <div id="notLoggedIn">
        <h3>Please log in now</h3>
        <section id="spicyDiv">
          <span className="label">login: </span><input id="login" name="login"
            type="text"
            value={login}
            onChange={e => setLogin(e.target.value)} />
          <span className="label">password: </span><input id="password" name="password" type="password" value={password}
            onChange={e => setPassword(e.target.value)} />
          <span className="label"><button id="loginsubmit" onClick={handleSubmit}>log in</button></span>
        </section>
      </div>
    )
  }

}

export default Front;
